import Adminlayout from "./layout";
import Loader from "./loader";


const components = {
  Adminlayout,
  Loader,
};

export default components;
