import React, { createContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

type ContextProps = [
  authenticated: boolean,
  isLoading: boolean,
  user: object | null
];

export const SessionContext = createContext<ContextProps>([true, false, null]);

export const SessionProvider: React.FC = (props) => {
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const [user, setUser] = useState<object | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user !== null) {
        const refreshToken = await user.getIdTokenResult();

        const isAdmin = refreshToken.claims.role === "admin";

        if (isAdmin) {
          setUser({ ...user, ...refreshToken.claims });
          setAuthenticated(true);
          setLoading(false);
        } else {
          await firebase.auth().signOut();
        }
      } else {
        setAuthenticated(false);
        setUser(null);
        setLoading(false);
      }
    });
  }, []);

  return (
    <SessionContext.Provider value={[authenticated, isLoading, user]}>
      {props.children}
    </SessionContext.Provider>
  );
};
