import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "assets/styles/loader.less";

interface Props {
  tip: string;
  spinning: boolean;
}

const Spinner: React.FC<Props> = ({ spinning, tip }) => {
  return (
    <div className={"spinner-container"}>
      <Spin
        className={"spin"}
        size={"large"}
        spinning={spinning}
        tip={""}
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      />
    </div>
  );
};

export default Spinner;
