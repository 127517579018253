import {
  LayoutOutlined,
  LineChartOutlined,
  AppstoreOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const data = {
  mainMenu: [
    {
      title: "Dashboard",
      link: "/kelola/dashboard",
      icon: <LineChartOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Pengguna",
      link: "/kelola/user",
      icon: <TeamOutlined style={{ fontSize: 18 }} />,
    },
  ],
  serviceMenu: [
    {
      title: "Layanan UMK",
      link: "/kelola/layanan/umk",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan non-UMK",
      link: "/kelola/layanan/nonumk",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan Yayasan",
      link: "/kelola/layanan/yayasan",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan Perjanjian",
      link: "/kelola/layanan/perjanjian",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan Hukum",
      link: "/kelola/layanan/hukum",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan Perizinan",
      link: "/kelola/layanan/perizinan",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan Merek",
      link: "/kelola/layanan/merek",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan Perpajakan",
      link: "/kelola/layanan/pajak",
      icon: <AppstoreOutlined style={{ fontSize: 18 }} />,
    },
  ],
  webMenu: [
    {
      title: "Promo",
      link: "/web/promo",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Carousel",
      link: "/web/carousel",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Layanan",
      link: "/web/layanan",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Testimoni",
      link: "/web/testimoni",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Faq",
      link: "/web/faq",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Portofolio",
      link: "/web/portofolio",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Supported",
      link: "/web/supported",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Liput Oleh",
      link: "/web/input",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Contact",
      link: "/web/contact",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
    {
      title: "Artikel",
      link: "/web/artikel",
      icon: <LayoutOutlined style={{ fontSize: 18 }} />,
    },
  ],
};

export default data;
