import ReactDOM from "react-dom";
import Router from "router";
import reportWebVitals from "./reportWebVitals";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "antd/dist/antd.less";
import "assets/styles/main.less";

// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyBV3sujUqcE1eE_9zsFcfRWUSzvnVBHtHU",
  authDomain: "akses-legal-indonesia.firebaseapp.com",
  projectId: "akses-legal-indonesia",
  storageBucket: "akses-legal-indonesia.appspot.com",
  messagingSenderId: "789434645486",
  appId: "1:789434645486:web:39d51fa8980d9d0c7f6cff",
});

// Use Firebase Emulators
if (window.location.hostname === "localhost") {
  // firebase.firestore().useEmulator("localhost", 8080);
  // firebase.functions().useEmulator("localhost", 5001);
  // firebase.auth().useEmulator('http://localhost:9099');
}

ReactDOM.render(<Router />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
